<template>
  <div v-if="show" class="modal-detail">
    <vxe-modal v-model="show" :title="name" width="400" min-width="400" height="210" min-height="210" showFooter
      esc-closable mask-closable resize>
      <template v-slot>
        <el-form ref="form" size="small" :model="data" label-width="120px" label-position="top" :rules="rules">
          <el-form-item prop="name" label="角色名称">
            <el-input v-model="data.name" placeholder="输入角色名称" type="text"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <div style="text-align: center">
          <vxe-button status="primary" style="width: 100%" @click="save" v-loading="loading">创建</vxe-button>
        </div>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      name: '',
      data: {},
      rules: {
        name: [{ required: true, trigger: 'blur', message: '输入角色名称' }]
      },
      loading: false
    }
  },
  methods: {
    display(data) {
      this.show = true
      this.data = data.data
      this.name = data.name
    },
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const params = this.data
          this.$axios
            .post('/auth/tenant/role/create', params)
            .then(res => {
              if (res) {
                this.$message.success('创建成功')
                this.show = false
                this.$emit('init')
              }
              this.loading = false
            })
            .catch(err => {
              console.log('创建失败', err)
              this.loading = false
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-detail {
  ::v-deep .vxe-modal--content {
    white-space: normal !important;
  }
}
</style>