<template>
  <div class="base-container">
    <div class="cf" style="margin-bottom: 10px">
      <div class="fl">
        <el-button type="primary" @click="getAdd()" size="mini" class="el-icon-plus">
          创建角色
        </el-button>
        <el-button @click="exportDataEvent()" size="mini">导出</el-button>
        <el-button @click="printDataEvent()" size="mini">打印</el-button>
      </div>
      <div class="fr">
        <el-button @click="init()" size="mini">刷新</el-button>
      </div>
    </div>
    <vxe-table :align="allAlign" :data="data" :height="tableHeight" border :export-config="{}" :print-config="{}"
      highlight-hover-row auto-resize size="small" ref="diverSign" resizable row-id="id" v-loading="loading"
      element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" show-header-overflow show-overflow>
      <template #empty>
        <el-empty></el-empty>
      </template>
      <vxe-table-column type="checkbox" width="45" fixed="left" align="center"></vxe-table-column>
      <vxe-table-column field="name" width="150" title="角色名称"></vxe-table-column>
      <vxe-table-column field="code" width="180" title="角色编号"></vxe-table-column>
      <vxe-table-column field="list" min-width="200" title="已分配的菜单">
        <template v-slot="{ row }">
          <el-tag v-for="(item, index) in row.list" :key="index" type="success" disable-transitions size="small"
            style="margin:5px">{{item.name}}
          </el-tag>
        </template>
      </vxe-table-column>
      <vxe-table-column field="systemDefault" width="150" title="是否基础角色">
        <template v-slot="{row}">
          <el-tag :type="row.systemDefault ? 'success' : 'primary'" size="small">
            {{ row.systemDefault ? '是' : '否' }}</el-tag>
        </template>
      </vxe-table-column>
      <vxe-table-column fixed="right" align="center" width="160" title="操作">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="roleMenu(scope.row)" icon="el-icon-c-scale-to-original">分配菜单
          </el-button>
          <el-button v-if="!scope.row.systemDefault" type="text" size="mini" icon="el-icon-delete"
            @click="getDelete(scope.row)">删除</el-button>
        </template>
      </vxe-table-column>
    </vxe-table>
    <add ref="add" @init="init()" />
    <role-menu ref="roleMenu" @init="init()" />
  </div>
</template>

<script>
import add from './components/add'
import roleMenu from './components/roleMenu'

export default {
  name: 'role',
  data() {
    return {
      data: [],
      tableHeight: window.innerHeight - 190 + 'px',
      allAlign: 'center',
      loading: false,
      tenantName: null,
      isAdmin: false,
    }
  },
  created() {
    this.init()
    const userRole = sessionStorage.getItem('userRole')
    const userList = !['', null, undefined].includes(userRole) ? userRole.split(',') : []
    this.isAdmin = userList.includes('admin') ? true : false
  },
  components: { add, roleMenu },
  mounted() {
    //监听元素变化
    window.onresize = () => {
      if (this.$refs.diverSign) {
        this.tableHeight = window.innerHeight - this.$refs.diverSign.$el.offsetTop - 125 + 'px'
      }
    }
    this.tenantName = localStorage.getItem('tenantName')
  },
  methods: {
    init() {
      this.loading = true
      this.$axios
        .get('/auth/tenant/role/list')
        .then((res) => {
          if (res) this.data = res.data.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    exportDataEvent() {
      this.$refs.diverSign.openExport()
    },
    printDataEvent() {
      this.$refs.diverSign.openPrint()
    },
    getAdd() {
      let data = {
        data: {},
        name: '创建角色',
      }
      this.$refs.add.display(data)
    },
    getDelete(row) {
      this.$confirm(`确定删除${row.name}角色吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$axios.get(`/auth/tenant/role/del/${row.id}`).then(() => {
            this.$message.success('删除角色成功!')
            this.init()
          })
        })
        .catch(() => {})
    },
    roleMenu(row) {
      const data = { dataList: [], dataValues: [], name: '分配菜单', row: row }
      this.$axios.get('/auth/menu/list').then((res) => {
        if (res) {
          data.dataList = res.data.data
          data.dataValues = this.$utils.map(row.list, (item) => item.menuId)
          this.$refs.roleMenu.display(data)
        }
      })
    },
  },
}
</script>

<style scoped></style>
